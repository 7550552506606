import { mapActions, mapGetters } from 'vuex'

import validationMixin from '@/mixins/validation'

import ContentForm from '../../components/content-form/index.vue'

import { eventStatuses } from '@/modules/events/heplers'

export default {
  name: 'events-content',
  mixins: [validationMixin],
  data () {
    return {
      locale: '',
      id: this.$route.params.id
    }
  },
  components: {
    ContentForm
  },
  computed: {
    ...mapGetters({
      content: 'eventsContent/content',
      isLoading: 'eventsContent/isLoading',
      accessCountries: 'profile/accessCountries'
    }),
    eventStatuses,
    listLocales () {
      if (this.accessCountries && this.content) {
        const currentCountry = this.accessCountries.find(e => e.country_id === this.content.country)
        if (currentCountry) {
          return currentCountry.locales.data
        } else {
          return []
        }
      }
      return []
    }
  },
  created () {
    if (!this.content) {
      this.fetchContent({
        id: this.$route.params.id,
        module: this.$route.name
      }).then(response => {
        this.locale = response.default_locale
      })
    }
  },
  methods: {
    ...mapActions({
      fetchContent: 'eventsContent/CONTENT_GET_DATA'
    })
  }
}
