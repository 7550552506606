import { mapActions, mapGetters } from 'vuex'

import validationMixin from '@/mixins/validation'

import MainEditor from '@/components/editor/index.vue'

export default {
  name: 'content-form',
  mixins: [validationMixin],
  props: {
    locale: {
      type: String
    },
    statuses: {
      type: Array
    }
  },
  data () {
    return {
      payload: {
        title: '',
        shortContent: '',
        content: '',
        locale: '',
        status: ''
      },
      valid: false,
      openDatepicker: false,
      validationErrors: {}
    }
  },
  components: {
    MainEditor
  },
  computed: {
    ...mapGetters({
      content: 'eventsContent/content',
      contentUpdateLoading: 'eventsContent/contentUpdateLoading',
      accessCountries: 'profile/accessCountries'
    })
  },
  created () {
    this.setContent()
  },
  methods: {
    ...mapActions({
      update: 'eventsContent/UPDATE',
      create: 'eventsContent/CREATE'
    }),
    setContent () {
      this.payload.locale = this.locale
      const data = this.content.content.find(e => e.locale === this.payload.locale)
      if (data) {
        Object.assign(this.payload, data)
      }
    },
    createRequestPayload () {
      let data
      const item = this.content.content.find(e => e.locale === this.payload.locale)
      if (!item) {
        data = {
          locale: this.payload.locale,
          event_id: this.$route.params.id,
          status: this.payload.status,
          title: this.payload.title,
          short_content: this.payload.shortContent,
          content: this.payload.content
        }
      } else {
        data = {
          id: item.id,
          status: this.payload.status,
          title: this.payload.title,
          short_content: this.payload.shortContent,
          content: this.payload.content
        }
      }
      return data
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        const item = this.content.content.find(e => e.locale === this.payload.locale)
        if (!item) {
          this.create({
            ...this.createRequestPayload(),
            module: this.$route.name
          }).then(() => {
            this.$toasted.success(this.$t('newsSuccessCreateContent'))
          })
        } else {
          this.update({
            ...this.createRequestPayload(),
            module: this.$route.name
          }).then(() => {
            this.$toasted.success(this.$t('newsSuccessUpdateContent'))
          })
        }
      }
    }
  }
}
